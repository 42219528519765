import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';
import { ajax } from 'rxjs/ajax';

class ClientService {

    static getUserClientStatsRequest(targetUserId)
    {
        return {
            targetUserId
        };
    }

    static getUserClientStats({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) 
    {
        endpointRequest({
            url: config.url.admin.userClientStats,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static loadIP()
    {
        ajax({
            url: config.url.publicIP,
            crossDomain: true
        }).subscribe(
            result => {
                if(result && result.response && result.response.ip)
                {
                    window['BxPublicIP'] = result.response.ip
                }
            }
        );
    }

    static getIP()
    {
        return window && window['BxPublicIP'] ? window['BxPublicIP'] : 'loading';
    }
    
    static getSearchClientSystemsUserRequest(clientUserId, page)
    {
        return {
            clientUserId,
            page
        };
    }

    /* istanbul ignore next */
    static searchClientSystemsUser({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.searchClientSystemsUser,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }
}

export default ClientService;
