import React from 'react';
import UserOffersPanel from './../user-offers';
import UserSeeksPanel from './../user-seeks';
import UserReviewsPanel from './../user-reviews';
import AdminUserDetailPanel from './../admin/admin-user-detail';
import { customTrailUi } from './../trails/view';
import theme from './../../theme';
import { useState, useEffect } from 'react';
import LogoPanel from './../logo';
import ResizeService from './../../../service/resize';
import Dialog from '@mui/material/Dialog';

const OpenRelationshipPrompt = ({data, onBlockUser, onFriendUser }) => 
    <div style={{
        ...theme.getUserDetailRelationButtonsContainerStyle(theme),
    }}>
        <button 
            style={{
                ...theme.getUserDetailOpenRelationButtonStyle(theme),
            }}
            className="test-userDetailBlockBtn"
            onClick={onBlockUser}>
            Block
        </button>
        <button 
            style={{
                ...theme.getUserDetailOpenRelationButtonStyle(theme),
            }}
            className="test-userDetailFriendBtn"
            onClick={onFriendUser}>
            Friend
        </button>
    </div>

const BlockedPanel = ({ data, onUnblockUser }) => {
    const [ modalOpen, setModalOpen ] = useState(false);
    return (
        <div style={{
            ...theme.getUserDetailRelationButtonsContainerStyle(theme),  
        }}>
            <button 
                style={{
                    ...theme.getUserDetailBlockedPanelButtonStyle(theme),
                }}
                className="test-userDetailUnblockBtn"
                onClick={event => { setModalOpen(true) }}>
                UnBlock
            </button>
            <Dialog
                onClose={ 
                    /* istanbul ignore next */
                    event => { setModalOpen(false) } 
                }
                open={modalOpen}
            >
                <div style={{
                    ...theme.getConfirmArchiveItemModalContainerStyle(theme)
                }}>
                    <div style={{
                        ...theme.getConfirmArchiveItemModalTextStyle(theme)
                    }}>
                        Are you sure you want to unblock {data.user.username}?
                    </div>
                    <div>
                        <button onClick={onUnblockUser}>
                            UnBlock
                        </button>
                        <button onClick={event => { setModalOpen(false) }}>
                            Cancel
                        </button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

const FriendPanel = ({ data, onUnfriendUser }) => {
    const [ modalOpen, setModalOpen ] = useState(false);
    return (
        <div style={{
            ...theme.getUserDetailRelationButtonsContainerStyle(theme),  
        }}>
            <button 
                style={{
                    ...theme.getUserDetailFriendPanelButtonStyle(theme),
                }}
                className="test-userDetailUnfriendBtn"
                onClick={event => { setModalOpen(true) }}>
                Unfriend
            </button>
            <Dialog
                onClose={ 
                    /* istanbul ignore next */
                    event => { setModalOpen(false) } 
                }
                open={modalOpen}
            >
                <div style={{
                    ...theme.getConfirmArchiveItemModalContainerStyle(theme)
                }}>
                    <div style={{
                        ...theme.getConfirmArchiveItemModalTextStyle(theme)
                    }}>
                        Are you sure you want to unfriend {data.user.username}?
                    </div>
                    <div>
                        <button onClick={onUnfriendUser}>
                            Unfriend
                        </button>
                        <button onClick={event => { setModalOpen(false) }}>
                            Cancel
                        </button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
 
const YourProfilePanel = ({ data, onEditProfile }) => 
    <div style={{
        ...theme.getUserDetailRelationButtonsContainerStyle(theme),  
    }}>
        <button 
            style={{
                ...theme.getUserDetailYourProfileButtonStyle(theme),
            }}
            className="test-userDetailManageMyProfileBtn"
            onClick={event => { onEditProfile(event) }}
        >
            Manage My Profile
        </button>
    </div>

const SpecifiedRelationshipPanel = ({data, onBlockUser, onUnblockUser, onFriendUser, onUnfriendUser}) => 
    <section>
        { data.relationship === 'blocked' ? BlockedPanel({ data, onUnblockUser }) : FriendPanel({ data, onUnfriendUser }) }
    </section>

const RelationshipPanel = ({ data, onBlockUser, onUnblockUser, onFriendUser, onUnfriendUser, onEditProfile }) => 
    <section>
        {
            data.relationship === 'You' ? YourProfilePanel({ data, onEditProfile }) : (
                data.relationship ? SpecifiedRelationshipPanel({data, onBlockUser, onUnblockUser, onFriendUser, onUnfriendUser}) : OpenRelationshipPrompt({data, onBlockUser, onFriendUser})
            )
        }
    </section>

export const PlatformsViewPanel = ({ trailList }) => 
    <section>
        { trailList.map((aTrail, index) => 
                <div style={{
                        ...theme.getUserDetailAPlatformContainerStyle(theme),
                    }}
                    key={index}
                >
                    <div style={{
                        ...theme.getTrailsCustomContainerStyle(theme),
                    }}>
                        { customTrailUi({aTrail, index }) }
                    </div>

                    <div style={{ 
                        ...theme.getGeneralTextStyle(theme),
                    }}>
                        { aTrail.username }
                    </div>
                    
                </div>
            ) 
        }
        <div style={{ 
            clear: 'both'
        }}></div>
    </section>

const UserDetailProfileDetails = ({ data }) => {
    const hasPlatforms = data.user.trailList.length > 0;
    const [hasOffers, setHasOffers] = useState(true);
    const [hasSeeks, setHasSeeks] = useState(true);
    const [hasReviews, setHasReviews] = useState(true);

    const getConStyle = () => theme.getUserDetailPlatformsContainerStyle(theme);
    const [ conStyle, setConStyle ] = useState( getConStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setConStyle( getConStyle() )
    });
    useEffect(resizeEffect);
    return (
        /* istanbul ignore next */
        (!hasPlatforms && !hasOffers && !hasSeeks && !hasReviews) ? <LogoPanel /> :
        <section>
            { 
                hasPlatforms ? 
                    <div style={conStyle}>
                        <h3>
                            Platforms
                        </h3>
                        <PlatformsViewPanel trailList={data.user.trailList} />
                    </div> : ''
            }
            {
                hasReviews ? 
                    <UserReviewsPanel 
                        ratedUserId={data.user.id}
                        setHasReviews={setHasReviews}
                    /> : ''
            }
            {
                hasOffers ? 
                    <UserOffersPanel 
                        setHasOffers={setHasOffers}
                        userId={data.user.id} 
                    /> : ''
            }
            {
                hasSeeks ? 
                    <UserSeeksPanel 
                        setHasSeeks={setHasSeeks}
                        userId={data.user.id} 
                    /> : ''
            }
        </section>
    );
};

/* istanbul ignore next */
const AdminSection = ({ data, isAdmin }) => isAdmin ? 
    <div style={{
        ...theme.getUserDetailAdminPanelContainerStyle(theme),
    }}>
        <AdminUserDetailPanel restrictedUser={data.user} />
    </div> : ''

const UserDetailViewPanel = ({ data, isAdmin, onBlockUser, onUnblockUser, onFriendUser, onUnfriendUser, onEditProfile }) => 
    <section>
        <section>
            <AdminSection isAdmin={isAdmin} data={data} />
            
            <RelationshipPanel 
                data={data} 
                onBlockUser={onBlockUser} 
                onUnblockUser={onUnblockUser} 
                onFriendUser={onFriendUser} 
                onUnfriendUser={onUnfriendUser} 
                onEditProfile={onEditProfile} 
            />

            <h2 style={{
                ...theme.getGeneralTextStyle(theme),
            }}> 
                { data.user.username }
            </h2>

        </section>
        
        <UserDetailProfileDetails data={data} />

    </section>

export default UserDetailViewPanel;
