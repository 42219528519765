const servers = {
    open: {
        protocol: process.env.REACT_APP_SERVER_OPEN_PROTOCOL,
        domainName: process.env.REACT_APP_SERVER_OPEN,
        port: process.env.REACT_APP_SERVER_OPEN_PORT
    },
    auth: {
        protocol: process.env.REACT_APP_SERVER_AUTH_PROTOCOL,
        domainName: process.env.REACT_APP_SERVER_AUTH,
        port: process.env.REACT_APP_SERVER_AUTH_PORT
    },
    admin: {
        protocol: process.env.REACT_APP_SERVER_ADMIN_PROTOCOL,
        domainName: process.env.REACT_APP_SERVER_ADMIN,
        port: process.env.REACT_APP_SERVER_ADMIN_PORT,
    },
    fileUpload: {
        protocol: process.env.REACT_APP_SERVER_UPLOAD_PROTOCOL,
        domainName: process.env.REACT_APP_SERVER_UPLOAD,
        port: process.env.REACT_APP_SERVER_UPLOAD_PORT,
    },
    widgets: {
        protocol: process.env.REACT_APP_SERVER_WIDGETS_PROTOCOL,
        domainName: process.env.REACT_APP_SERVER_WIDGETS,
        port: process.env.REACT_APP_SERVER_WIDGETS_PORT,
    },
    account: {
        protocol: process.env.REACT_APP_SERVER_ACCOUNT_PROTOCOL,
        domainName: process.env.REACT_APP_SERVER_ACCOUNT,
        port: process.env.REACT_APP_SERVER_ACCOUNT_PORT,
    },
    directory: {
        protocol: process.env.REACT_APP_SERVER_DIRECTORY_PROTOCOL,
        domainName: process.env.REACT_APP_SERVER_DIRECTORY,
        port: process.env.REACT_APP_SERVER_DIRECTORY_PORT,
    },
    search: {
        protocol: process.env.REACT_APP_SERVER_SEARCH_PROTOCOL,
        domainName: process.env.REACT_APP_SERVER_SEARCH,
        port: process.env.REACT_APP_SERVER_SEARCH_PORT,
    },
    publish: {
        protocol: process.env.REACT_APP_SERVER_PUBLISH_PROTOCOL,
        domainName: process.env.REACT_APP_SERVER_PUBLISH,
        port: process.env.REACT_APP_SERVER_PUBLISH_PORT,
    },
    exchange: {
        protocol: process.env.REACT_APP_SERVER_EXCHANGE_PROTOCOL,
        domainName: process.env.REACT_APP_SERVER_EXCHANGE,
        port: process.env.REACT_APP_SERVER_EXCHANGE_PORT,
    },
};

const getBaseUrl = ser => ['80', '443'].includes(ser.port) ? `${ser.protocol}${ser.domainName}` : 
    `${ser.protocol}${ser.domainName}:${ser.port}`;

const openServerBaseUrl = getBaseUrl(servers.open);
const authServerBaseUrl = getBaseUrl(servers.auth);
const adminServerBaseUrl = getBaseUrl(servers.admin);
const fileUploadServerBaseUrl = getBaseUrl(servers.fileUpload);
const widgetServerBaseUrl = getBaseUrl(servers.widgets);
const accountServerBaseUrl = getBaseUrl(servers.account);
const directoryServerBaseUrl = getBaseUrl(servers.directory);
const searchServerBaseUrl = getBaseUrl(servers.search);
const publishServerBaseUrl = getBaseUrl(servers.publish);
const exchangeServerBaseUrl = getBaseUrl(servers.exchange);

const config = {
    version: process.env.REACT_APP_CLIENT_VERSION,
    interval: {
        postLoginResizeEvent: 100,
        getUnseenNotices: 15000,
        getDeviceConnectionHealth: 1000,
        endpointDebounce: 1000,
        reloadOnResize: 200,
        menuScroll: 100,
        checkJobStatus: 6000,
        offlineNetworkBackOnline: 500
    },
    countLimits: {
        customFields: 30,
        deliveryOptions: 10,
        exchangeOptions: 10,
        tags: 5
    },
    img: {
        maxUploadCount: 5,
    },
    features: {
        // feature flags
        allowCarrierGroups: false,
    },
    debug: {
        displayDeviceConnection: false // just for debugging - this will error unit tests if true
    },
    inputLimit: {
        textLine: 112,
        textLines: 2170
    },
    map: {
        clientKey: process.env.REACT_APP_CLIENT_MAP_API_KEY,
        
    },
    pay: {
        clientKey: process.env.REACT_APP_CLIENT_PAY_API_KEY,
        paymentMethodAddedUrl: process.env.REACT_APP_PAY_METHOD_ADDED_URL,
        bankAccountAddedUrl: process.env.REACT_APP_BANK_ACCOUNT_ADDED_URL,
        onboardingCompletedUrl: process.env.REACT_APP_ONBOARDING_COMPLETED_URL,
        paymentNewCompletedUrl: process.env.REACT_APP_PAY_NEW_COMPLETED_URL,
        finePaymentCompletedUrl: process.env.REACT_APP_PAY_FINE_COMPLETED_URL,
        finePaymentReloadCompletedUrl: process.env.REACT_APP_PAY_FINE_COMPLETED_RELOAD_URL,
        paymentConfirmedUrl: process.env.REACT_APP_PAY_CONFIRMED_URL,
        subscriptionConfirmedUrl: process.env.REACT_APP_SUBSCRIPTION_PAY_CONFIRMED_URL,
        finePayReloadDelay: 600,
        reloadDelay: 1000, 
        paid: {
            flatFee: 35,
            feePercent: 0.03,
            displayFeePercent: '3',
            minListedPrice: 37
        },
        free: {
            flatFee: 50,
            feePercent: 0.07,
            displayFeePercent: '7',
            minListedPrice: 55
        },
        fine: {
            dispute: {
                total: '1700',
                currency: 'USD'
            }
        },
        usps: {
            limit: '$1,000',
            maxAmount: 100000,
            lowFee: 165,
            lowFeeMaxAmount: 50000,
            highFee: 220
        },
        submitPaymentMethodFeedbackDelay: 3000,
        maxFailures: process.env.REACT_APP_PAY_MAX_CONFIRM_CHECKS ?? 20,
        subscriptionPrice: process.env.REACT_APP_PAY_SUB_PRICE ?? '2911'
    },
    redirects: {
        baseBid: '/bid/',
        baseFriend: '/user/',
        baseExchange: '/exchange/',
        baseOffer: '/offer/',
        baseSeek: '/seek/',
        baseIssue: '/profile/reported/issue/',
        invite: '/join',
        payAccount: '/profile/income',
        subscription: '/subscription',
        userFines: '/profile/fees',
        carrierGroupInvites: '/profile/groups/carrier/invites',
        carrierMembersPre: '/profile/groups/carrier/',
        userFinesPaid: '/completed/fee'
    },
    url: {
        publicIP: 'https://api64.ipify.org/?format=json',
        networkTest: process.env.REACT_APP_NETWORK_TEST_PX_URL ?? 'https://bears.exchange/video/networkTestPx.png',
        videoDir: process.env.REACT_APP_HELP_VIDEO_URL ?? 'https://bears.exchange/video/',
        unauth: {
            join: `${openServerBaseUrl}/join`,
            login: `${openServerBaseUrl}/login`,
            loginWithToken: `${openServerBaseUrl}/token/login`,
            forgot: `${openServerBaseUrl}/forgot`,
            unsubscribe: `${openServerBaseUrl}/unsubscribe`,
            log: `${openServerBaseUrl}/log`
        },
        client: {
            log: `${authServerBaseUrl}/log`
        },
        widgets: {
            add: `${widgetServerBaseUrl}/user/widgets/add`,
            list: `${widgetServerBaseUrl}/user/widgets/list`,
            reorder: `${widgetServerBaseUrl}/user/widgets/reorder`,
            remove: `${widgetServerBaseUrl}/user/widgets/remove`,
        },
        widget: {
            news: {
                list: `${widgetServerBaseUrl}/widget/news`
            },
            pendingBids: {
                list: `${widgetServerBaseUrl}/widget/bids/pending`
            },
        },
        location: {
            get: `${accountServerBaseUrl}/user/location/get`,
            add: `${accountServerBaseUrl}/user/location/add`,
            list: `${accountServerBaseUrl}/user/locations`,
            remove: `${accountServerBaseUrl}/user/location/remove`,
            repair: `${accountServerBaseUrl}/user/location/repair`,
            correct: `${accountServerBaseUrl}/user/location/correct`,
            removeNotice: `${accountServerBaseUrl}/user/location/notice/remove`,
            setAsDefault: `${accountServerBaseUrl}/user/location/default`,
        },
        delivery: {
            network: {
                addToQueue: `${exchangeServerBaseUrl}/delivery/shipping/network/request`,
                listAvailable: `${exchangeServerBaseUrl}/delivery/shipping/network/list`,
            },
            assign: `${exchangeServerBaseUrl}/delivery/shipping/assign`,
            received: `${exchangeServerBaseUrl}/delivery/shipping/received`,
        },
        exchange: {
            details: `${exchangeServerBaseUrl}/exchange/details`,
            detailsByPayId: `${exchangeServerBaseUrl}/exchange/pay/details`,
            search: `${searchServerBaseUrl}/exchange/search`,
            searchArchive: `${searchServerBaseUrl}/exchange/archive/search`,
            searchActive: `${searchServerBaseUrl}/exchange/active/search`,
            chats: `${exchangeServerBaseUrl}/exchange/chat/list`,
            speak: `${fileUploadServerBaseUrl}/exchange/chat/add`,
            update: `${exchangeServerBaseUrl}/exchange/update`,
            confirmPay: `${exchangeServerBaseUrl}/checkout/pay/confirm`,
            requestRefund: `${exchangeServerBaseUrl}/exchange/refund/request`,
            reviewRefund: `${exchangeServerBaseUrl}/exchange/refund/review`,
            disputeDetails: `${exchangeServerBaseUrl}/exchange/dispute/details`,
            disputeRespond: `${fileUploadServerBaseUrl}/exchange/dispute/respond`,
            disputeLoss: `${exchangeServerBaseUrl}/exchange/dispute/loss`,
        },
        tag: {
            list: `${searchServerBaseUrl}/tag/list`,
            populated: {
                seek: `${searchServerBaseUrl}/tag/populated/seek`,
                offer: `${searchServerBaseUrl}/tag/populated/offer`
            },
        },
        report: {
            issue: `${publishServerBaseUrl}/report/issue`,
            listIssues: `${accountServerBaseUrl}/reported/issues/list`,
            issueDetails: `${accountServerBaseUrl}/reported/issues/details`,
            speak: `${fileUploadServerBaseUrl}/reported/issue/chat/add`,
        },
        bid: {
            detail: `${searchServerBaseUrl}/bid/detail`,
            setApprovalState: `${publishServerBaseUrl}/bid/approval`,
        },
        admin: {
            stats: `${adminServerBaseUrl}/admin/stats`,
            sendEmail: `${adminServerBaseUrl}/admin/test/email`,
            requestVolume: `${adminServerBaseUrl}/admin/services/volume`,
            metrics: `${adminServerBaseUrl}/admin/app/metrics`,
            metricsSummary: `${adminServerBaseUrl}/admin/app/metrics/summary`,
            clientStats: `${adminServerBaseUrl}/admin/client/stats`,
            issueInvites: `${adminServerBaseUrl}/admin/invites/issue`,
            pendingFlags: `${adminServerBaseUrl}/admin/flags/pending`,
            reviewFlag: `${adminServerBaseUrl}/admin/flag/review`,
            userDetails: `${adminServerBaseUrl}/admin/user/detail`,
            userActions: {
                list: `${adminServerBaseUrl}/admin/user/actions/list`,
                add: `${adminServerBaseUrl}/admin/user/action/add`,
                update: `${adminServerBaseUrl}/admin/user/action/update`,
                delete: `${adminServerBaseUrl}/admin/user/action/delete`,
            },
            userClientStats: `${adminServerBaseUrl}/admin/user/client/stats`,
            userReportedIssues: `${adminServerBaseUrl}/admin/user/reported/issues`,
            userAccess: `${adminServerBaseUrl}/admin/user/access`,
            listApiLogs: `${adminServerBaseUrl}/admin/api/log`,
            listReportedIssues: `${adminServerBaseUrl}/admin/app/issues`,
            getReportedIssue: `${adminServerBaseUrl}/admin/app/issue`,
            searchClientSystemsUser: `${adminServerBaseUrl}/admin/client/systems/user`,
            updateReportedIssue: `${adminServerBaseUrl}/admin/issue/update`,
            publishNews: `${adminServerBaseUrl}/admin/news/add`,
            failedGeoLocations: `${adminServerBaseUrl}/admin/failed/geolocation`,
            repairGeoLocation: `${adminServerBaseUrl}/admin/repair/geolocation`,
            addTag: `${adminServerBaseUrl}/admin/tag/add`,
            removeTag: `${adminServerBaseUrl}/admin/tag/remove`,
            searchPayouts: `${adminServerBaseUrl}/admin/payouts/search`,
            viewPayTransDetails: `${adminServerBaseUrl}/admin/pay/trans/details`,
            reschedulePayoutJob: `${adminServerBaseUrl}/admin/payout/reschedule`,
            rescheduleIp: `${adminServerBaseUrl}/admin/ip/reschedule`,
            removeIp: `${adminServerBaseUrl}/admin/ip/delete`,
            ipSearch: `${adminServerBaseUrl}/admin/ip/search`,
            logSearch: `${adminServerBaseUrl}/admin/app/logs`,
            logPurge: `${adminServerBaseUrl}/admin/app/purge/logs`,
            fineSearch: `${adminServerBaseUrl}/admin/fines/search`,
            issueRefund: `${adminServerBaseUrl}/admin/refund/issue`,
            searchBxEvents: `${adminServerBaseUrl}/admin/event/search`,
            rescheduleBxEvent: `${adminServerBaseUrl}/admin/event/reschedule`,
            deleteBxEvent: `${adminServerBaseUrl}/admin/event/delete`,
            searchDisputes: `${adminServerBaseUrl}/admin/disputes/search`,
            searchLedger: `${adminServerBaseUrl}/admin/ledger/search`,
            archiveLedgerItem: `${adminServerBaseUrl}/admin/ledger/archive`,
            ledgerSummary: `${adminServerBaseUrl}/admin/ledger/summary`,
            depositLedger: `${adminServerBaseUrl}/admin/ledger/deposit`,
            confirmDeposit: `${adminServerBaseUrl}/admin/deposit/confirmed`,
            runMaintanceTask: `${adminServerBaseUrl}/admin/maintance/task`,
            approveEarlyPayout: `${adminServerBaseUrl}/admin/early-payout/approval`
        },
        notice: {
            unseenCount: `${authServerBaseUrl}/notice/unseen`, 
            list: `${authServerBaseUrl}/notice/list`,
            seen: `${authServerBaseUrl}/notice/seen`,
            delete: `${authServerBaseUrl}/notice/delete`,
            seeAll: `${authServerBaseUrl}/notice/purge/unseen`,
            deleteAll: `${authServerBaseUrl}/notice/purge/all`,
        },
        user: {
            group: {
                carrier: {
                    list: `${accountServerBaseUrl}/user/group/carrier/list`,
                    add: `${accountServerBaseUrl}/user/group/carrier/add`,
                    update: `${accountServerBaseUrl}/user/group/carrier/update`,
                    details: `${accountServerBaseUrl}/user/group/carrier/details`,
                    acceptDeliveries: `${accountServerBaseUrl}/user/group/carrier/toggle/open`,
                    join: {
                        search: `${searchServerBaseUrl}/user/group/carrier/join/search`,
                        invites: `${accountServerBaseUrl}/user/group/carrier/invites`,
                        toggle: `${accountServerBaseUrl}/user/group/carrier/toggle/joins`,
                    },
                    queue: {
                        list: `${exchangeServerBaseUrl}/delivery/quote/request/list`,
                        decline: `${exchangeServerBaseUrl}/delivery/quote/request/decline`,
                    },
                    plan: {
                        startEditing: `${accountServerBaseUrl}/user/group/carrier/plan/edit/start`,
                        stopEditing: `${accountServerBaseUrl}/user/group/carrier/plan/edit/stop`,
                        forceUnlock: `${accountServerBaseUrl}/user/group/carrier/plan/edit/unlock/force`,
                        viewEditHistory: `${accountServerBaseUrl}/user/group/carrier/plan/edit/history`,
                        addStep: `${accountServerBaseUrl}/user/group/carrier/plan/edit/step/add`,
                        removeStep: `${accountServerBaseUrl}/user/group/carrier/plan/edit/step/remove`,
                        listPossibleDestinations: `${accountServerBaseUrl}/user/group/carrier/plan/edit/step/destinations/list`,
                        status: {
                            completeDraft: `${accountServerBaseUrl}/user/group/carrier/plan/draft/complete`,
                            completeScheduling: `${accountServerBaseUrl}/user/group/carrier/plan/scheduling/complete`,
                            pricingStatus: `${accountServerBaseUrl}/user/group/carrier/plan/pricing/status`,
                            completePricing: `${accountServerBaseUrl}/user/group/carrier/plan/pricing/complete`,
                            completeConfirming: `${accountServerBaseUrl}/user/group/carrier/plan/confirming/complete`,
                            declineConfirming: `${accountServerBaseUrl}/user/group/carrier/plan/confirming/decline`,
                        },
                    },
                    trip: {
                        userList: `${accountServerBaseUrl}/user/group/carrier/trip/user/list`,
                        create: `${accountServerBaseUrl}/user/group/carrier/trip/user/create`,
                        stop: {
                            add: `${accountServerBaseUrl}/user/group/carrier/trip/stop/add`,
                        },
                        cargo: {
                            remove: `${accountServerBaseUrl}/user/group/carrier/trip/cargo/remove`
                        },
                    },
                    locations: {
                        list: `${accountServerBaseUrl}/user/group/carrier/location/list`,
                        add: `${accountServerBaseUrl}/user/group/carrier/location/add`,
                        remove: `${accountServerBaseUrl}/user/group/carrier/location/remove`,
                    },
                    member: {
                        add: `${fileUploadServerBaseUrl}/user/group/carrier/member/add`,
                        update: {
                            contactInfo: `${accountServerBaseUrl}/user/group/carrier/member/contact/update`,
                            roles: `${accountServerBaseUrl}/user/group/carrier/member/roles/update`,
                        },                        
                        list: `${accountServerBaseUrl}/user/group/carrier/members/list`,
                        pending: {
                            add: `${fileUploadServerBaseUrl}/user/group/carrier/member/pending/create`,
                            list: `${accountServerBaseUrl}/user/group/carrier/member/pending/list`,
                            respond: `${fileUploadServerBaseUrl}/user/group/carrier/member/pending/respond`,
                        },
                        jobs: {
                            listPending: `${accountServerBaseUrl}/user/group/carrier/member/jobs/pending`,
                        },
                    },
                },
            },
            fines: {
                search: `${accountServerBaseUrl}/user/fines/search`,
                startPay: `${exchangeServerBaseUrl}/pay/fee/start`
            },
            email: {
                sendCode: `${accountServerBaseUrl}/user/email/change/send`,
                change: `${accountServerBaseUrl}/user/email/change/save`,
            },
            store: {
                removeTrait: `${accountServerBaseUrl}/user/store/trait/remove`,
                setTrait: `${accountServerBaseUrl}/user/store/trait/set`,
                listTraits: `${accountServerBaseUrl}/user/store/traits/list`,
            },
            checkout: {
                start: `${exchangeServerBaseUrl}/checkout/start`,
                isAvailable: `${exchangeServerBaseUrl}/checkout/available`
            },
            subscription: {
                current: `${accountServerBaseUrl}/user/subscription/status`,
                start: `${accountServerBaseUrl}/user/subscription/start`,
                cancel: `${accountServerBaseUrl}/user/subscription/cancel`,
                update: `${accountServerBaseUrl}/user/subscription/update`
            },
            payouts: {
                list: `${accountServerBaseUrl}/user/pay/transfer/list`
            },
            pay: {
                account: `${accountServerBaseUrl}/user/pay/account`,
                createAccount: `${accountServerBaseUrl}/user/pay/account/start`,
                updateAccount: `${accountServerBaseUrl}/user/pay/account/update`,
                finishAccount: `${accountServerBaseUrl}/user/pay/account/finish`,
                bankAccounts: `${accountServerBaseUrl}/user/bank/list`,
                methods: `${accountServerBaseUrl}/user/pay/methods`,
                addBank: `${accountServerBaseUrl}/user/pay/bank/add`,
                removeBankAccount: `${accountServerBaseUrl}/user/pay/bank/remove`,
                setPayoutBank: `${accountServerBaseUrl}/user/pay/bank/target`,
                addPayMethodSecret: `${accountServerBaseUrl}/user/pay/method/add/secret`,
                updatePayMethod: `${accountServerBaseUrl}/user/pay/method/update`,
                removePayMethod: `${accountServerBaseUrl}/user/pay/method/remove`,
            },
            search: `${directoryServerBaseUrl}/user/search`,
            details: `${directoryServerBaseUrl}/user/details`,
            updatePwd: `${accountServerBaseUrl}/pwd/update`,
            token: {
                create: `${accountServerBaseUrl}/token/create`,
                verify: `${accountServerBaseUrl}/token/verify`,
                modify: `${accountServerBaseUrl}/token/modify`,
                load: `${accountServerBaseUrl}/token/load`,
                remove: `${accountServerBaseUrl}/token/remove`,
            },
            flagItem: `${publishServerBaseUrl}/flag/item`,
            img: {
                list: `${publishServerBaseUrl}/img/list`,
                remove: `${publishServerBaseUrl}/img/remove`
            },
            settings: {
                client: `${accountServerBaseUrl}/user/setting/client/list`,
                list: `${accountServerBaseUrl}/user/setting/list`,
                apply: `${accountServerBaseUrl}/user/setting/apply`,
                consentToEmail: `${accountServerBaseUrl}/user/email/consent`,
            },

            relationship: {
                friend: {
                    add: `${accountServerBaseUrl}/user/friend/add`,
                    remove: `${accountServerBaseUrl}/user/friend/remove`,
                    search: `${searchServerBaseUrl}/user/friend/search`
                },
                block: {
                    add: `${accountServerBaseUrl}/user/block/add`,
                    remove: `${accountServerBaseUrl}/user/block/remove`,
                },
            },

            listings: {
                offerListings: `${searchServerBaseUrl}/user/listing/offers`,
                seekListings: `${searchServerBaseUrl}/user/listing/seeks`,
            },

            archives: {
                offers: `${searchServerBaseUrl}/user/archived/offers`,
                seeks: `${searchServerBaseUrl}/user/archived/seeks`,
            },

            rating: {
                addRating: `${directoryServerBaseUrl}/user/rating/add`,
                getRatings: `${directoryServerBaseUrl}/user/ratings/list`,
                getRatingsSummary: `${directoryServerBaseUrl}/user/ratings/summary`
            },
                
            trail: {
                addTrail: `${accountServerBaseUrl}/user/trail/add`,
                removeTrail: `${accountServerBaseUrl}/user/trail/remove`,
            },
            invites: `${directoryServerBaseUrl}/invites`,
        },
        trail: {
            search: `${directoryServerBaseUrl}/trails/search`,   
            add: `${adminServerBaseUrl}/trail/add`,
            update: `${adminServerBaseUrl}/trail/update`,
            remove: `${adminServerBaseUrl}/trail/remove`,
        },
        offer: {
            setupAdd: `${publishServerBaseUrl}/offer/setup/add`,
            add: `${fileUploadServerBaseUrl}/offer/add`,
            archive: `${publishServerBaseUrl}/offer/archive`,
            update: `${publishServerBaseUrl}/offer/update`,
            search: `${searchServerBaseUrl}/offers/search`,
            addBid: `${publishServerBaseUrl}/offer/bid/add`,
            listBids: `${searchServerBaseUrl}/offer/bid/list`,
            details: `${searchServerBaseUrl}/offer/details`,
            retired: `${searchServerBaseUrl}/offer/retired/details`,
        },
        seek: {
            setupAdd: `${publishServerBaseUrl}/seek/setup/add`,
            add: `${fileUploadServerBaseUrl}/seek/add`,
            archive: `${publishServerBaseUrl}/seek/archive`,
            update: `${publishServerBaseUrl}/seek/update`,
            search: `${searchServerBaseUrl}/seeks/search`,
            addBid: `${publishServerBaseUrl}/seek/bid/add`,
            listBids: `${searchServerBaseUrl}/seek/bid/list`,
            details: `${searchServerBaseUrl}/seek/details`,
            retired: `${searchServerBaseUrl}/seek/retired/details`,
        },
        attSet: {
            add: `${accountServerBaseUrl}/att-set/add`,
            search: `${accountServerBaseUrl}/att-set/search`,
            details: `${accountServerBaseUrl}/att-set/details`,
            remove: `${accountServerBaseUrl}/att-set/remove`,
        },
    }
};

export default config;
