import config from '../../config';
import iniOnboardingCrawler from './ini-onboarding-crawler';

const INI_STATE = {
    clientVersion: config.version,
    authToken: '',
    userId: '',
    isAdmin: false,
    unseenNoticeCount: 0,
    deviceIsOnline: true,
    displayError: false,
    errorMessage: '',
    showFullMenu: true,
    onboarding: {
        showOnboarding: false,
        onboardingCrawler: iniOnboardingCrawler
    },
    clientSettings: {
        showNoticeToasts: true,
        showAllNoticeToasts: false,
    },
    customView: {
        slowSpeedViewEnabled: false,
        autoAdjustSpeedViewEnabled: false,
        showSpeedViewPrompt: false,
        userHasClosedSpeedViewPrompt: true
    },
    metrics: {
        deviceConnect: {
            hasMetrics: false,
            health: {
                isHealthy: true,
                healthyPercent: 0
            },
            fast: {
                isFast: true,
                fastPercent: 0
            },
            stats: {
                average: {
                    value: 0,
                    unit: 'ms'
                },
                min: {
                    value: 0,
                    unit: 'ms'
                },
                max: {
                    value: 0,
                    unit: 'ms'
                },
                count: {
                    value: 0,
                    unit: 'request count'
                }
            }
        }
    }
};

export default INI_STATE;
