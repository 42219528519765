import React from 'react';
import theme from './../../../theme';
import PieChartPanel from './../../core/pie-chart';

const DEFAULT_COLOR = '#FFB6C1';
const colorList = [
    '#8B5F65',
    '#CD69C9',
    '#8B668B',
    '#B452CD',
    '#9A32CD',
    '#9B30FF',
    '#8B636C',
    '#9F79EE',
    '#0000EE',
    '#4169E1',
    '#6495ED',
    '#87CEFF',
    '#ADD8E6',
    '#00F5FF',
    '#00CDCD',
    '#DA70D6',
    '#00CD66',
    '#EE1289',
    '#008B00',
    '#8B8B00',
    '#8B475D',
];

// istanbul ignore next
const OSDetails = ({ osDetails }) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme),
    }}>
        <h3>
            OS
        </h3>
        {
            osDetails.length < 1 && 
            <p>
                No OS Data Has Been Collected
            </p>
        }
        <PieChartPanel 
            data={osDetails}
            style={{
                ...theme.getClientStatChartStyle(theme)
            }}
            mapper={ (aOS, index) => ({
                title: `${aOS._id.osName} ${aOS._id.osVersion}`,
                value: aOS.count,
                color: colorList[index] ? colorList[index] : DEFAULT_COLOR
            })}
        />
        {
            osDetails.map((aOS, index) => 
                <div 
                    key={index}
                    style={{
                        color: colorList[index] ? colorList[index] : DEFAULT_COLOR
                    }}
                >
                    { `${aOS._id.osName} ${aOS._id.osVersion} used ${aOS.count} times` }
                </div>
            )
        }
    </div>

// istanbul ignore next
const BrowserDetails = ({ browserDetails }) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme),
    }}>
        <h3>
            Browser
        </h3>
        {
            browserDetails.length < 1 && 
            <p>
                No Browser Data Has Been Collected
            </p>
        }
        <PieChartPanel 
            data={browserDetails}
            style={{
                ...theme.getClientStatChartStyle(theme)
            }}
            mapper={ (aBrowser, index) => ({
                title: `${aBrowser._id.browserName} ${aBrowser._id.browserVersionMajor}`,
                value: aBrowser.count,
                color: colorList[index] ? colorList[index] : DEFAULT_COLOR
            })}
        />
        {
            browserDetails.map((aBrowser, index) => 
                <div 
                    key={index}
                    style={{
                        color: colorList[index] ? colorList[index] : DEFAULT_COLOR
                    }}
                >
                    { `${aBrowser._id.browserName} ${aBrowser._id.browserVersionMajor} used ${aBrowser.count} times` }
                </div>
            )
        }
    </div>

export const SystemsReport = ({ data }) => 
    <div>
        { 
            data.os && 
            <OSDetails osDetails={data.os} />
        }

        {
            data.browser && 
            <BrowserDetails browserDetails={data.browser} />
        }
    </div>
    
// istanbul ignore next
const CountryDetails = ({ countries }) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme),
    }}>
        <h3>
            Countries
        </h3>
        {
            countries.length < 1 && 
            <p>
                No Country Data Has Been Collected
            </p>
        }
        <PieChartPanel 
            data={countries}
            style={{
                ...theme.getClientStatChartStyle(theme)
            }}
            mapper={ (aCountry, index) => ({
                title: `${aCountry._id.countryName}`,
                value: aCountry.count,
                color: colorList[index] ? colorList[index] : DEFAULT_COLOR
            })}
        />
        {
            countries.map((aCountry, index) => 
                <div 
                    key={index}
                    style={{
                        color: colorList[index] ? colorList[index] : DEFAULT_COLOR
                    }}
                >
                    { `${aCountry._id.countryName}` }
                </div>
            )
        }
    </div>

// istanbul ignore next
const CityDetails = ({ cities }) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme),
    }}>
        <h3>
            Cities
        </h3>
        {
            cities.length < 1 && 
            <p>
                No City Data Has Been Collected
            </p>
        }
        <PieChartPanel 
            data={cities}
            style={{
                ...theme.getClientStatChartStyle(theme)
            }}
            mapper={ (aCity, index) => ({
                title: `${aCity._id.city}, ${aCity._id.regionName}`,
                value: aCity.count,
                color: colorList[index] ? colorList[index] : DEFAULT_COLOR
            })}
        />
        {
            cities.map((aCity, index) => 
                <div 
                    key={index}
                    style={{
                        color: colorList[index] ? colorList[index] : DEFAULT_COLOR
                    }}
                >
                    { `${aCity._id.city}, ${aCity._id.regionName}` }
                </div>
            )
        }
    </div>

const LocationsReport = ({ data }) => 
    <div>
        {
            data.city && 
                <CityDetails cities={data.city} />
        }
        {
            data.country && 
                <CountryDetails countries={data.country} />
        }
    </div>

const AdminClientStatsViewPanel = ({ data }) => 
    <div>
        {
            data.systemsReport && 
                <SystemsReport data={data.systemsReport} />
        }
        {
            data.locationsReport && 
                <LocationsReport data={data.locationsReport} />
        }

    </div>

export default AdminClientStatsViewPanel;
