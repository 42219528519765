import React from 'react';
import UiService from './../../../../service/ui';
import theme from './../../../theme';
import { NavLink } from 'react-router-dom';
import SearchUserSystemsPrompt from './../../../prompt/admin/search-user-systems';
import UserSystemsGraphPanel from '../user-systems-graph';

// istanbul ignore next
const IssueDetails = ({ appIssue }) => 
    <div 
        style={{
            ...theme.getGeneralTextStyle(theme),
        }}
    >
        <section>
            <div style={{
                ...theme.getMediumTextStyle(theme),
            }}>
                <NavLink 
                    style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                    to={`/user/${appIssue.userId}`}
                >
                    Reporter
                </NavLink>
            </div>
            
            <div style={{
                ...theme.getSmallTextStyle(theme),
            }}>
                { UiService.timeSince(appIssue.createdOn) }
            </div>

        </section>
        <p style={ appIssue.unseen ? theme.getReportedIssueUnseenStyle(theme) : theme.getReportedIssueSeenStyle(theme) }>
            { appIssue.details }
        </p>
    </div>

const ReportedIssueViewPanel = ({ data }) => 
    <div>
        <IssueDetails appIssue={data.appIssue} />
        <UserSystemsGraphPanel targetUserId={data.appIssue.userId} />
        <SearchUserSystemsPrompt userId={data.appIssue.userId} />
    </div>

export default ReportedIssueViewPanel;
